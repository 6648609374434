import api from "./api"

export const getIBRequestsService = () => {
  return api.get("/v1/ib-request")
}

export const makeIbService = (id) => {
  return api.put("/v1/request-ib", {
    id: id,
  })
}

export const addTreeService = (user_id, commission_per_lot) => {
  return api.post("/v1/ib-tree", {
    user_id: user_id,
    commission_per_lot: commission_per_lot,
  })
}

export const addLevelService = (commission, ib_tree_id, level) => {
  return api.post("/v1/ib-tree-level", {
    commission: commission,
    ib_tree_id: ib_tree_id,
    level: level,
  })
}

export const getRequestsByType = (
  page = 1,
  type = null,
  status = null,
  selectedUser = undefined,
  downloadFile = false,
  name = "",
  transaction_flow = "",
  from = undefined,
  to = undefined
) => {
  let query = `page=${page}`

  let options = {}

  if (type) {
    query += `&type=${type}`
  }

  if (status && status != "ALL") {
    query += `&status=${status}`
  }

  if (selectedUser) {
    query += `&user_id=${selectedUser}`
  }

  if (name) {
    query += `&name=${name}`
  }

  if (transaction_flow) {
    query += `&transaction_flow=${transaction_flow}`
  }

  if (downloadFile) {
    query += `&cvs_download=true`
    options = {}
  }

  if (from) {
    query += `&fromDate=${from}`
  }

  if (to) {
    query += `&toDate=${to}`
  }

  return api.get(`/v1/requests?${query}`, options)
}

export const getAllRequestsByType = (
  page = 1,
  type = null,
  status = null,
  selectedUser = undefined,
  downloadFile = false,
  name = "",
  transaction_flow = "",
  from = undefined,
  to = undefined
) => {
  let query = `page=${page}`

  let options = {}

  if (type) {
    query += `&type=${type}`
  }

  if (status && status != "ALL") {
    query += `&status=${status}`
  }

  if (selectedUser) {
    query += `&user_id=${selectedUser}`
  }

  if (name) {
    query += `&name=${name}`
  }

  if (transaction_flow) {
    query += `&transaction_flow=${transaction_flow}`
  }

  if (downloadFile) {
    query += `&cvs_download=true`
    options = {}
  }

  if (from) {
    query += `&fromDate=${from}`
  }

  if (to) {
    query += `&toDate=${to}`
  }

  return api.get(`/v1/requests?${query}`, options)
}

export const getTransferRequests = (
  page = 1,
  user_id = undefined,
  downloadFile = false,
  name,
  from = undefined,
  to = undefined
) => {
  let query = `page=${page}`

  if (user_id) {
    query += `&user_id=${user_id}`
  }

  if (name) {
    query += `&name=${name}`
  }

  if (downloadFile) {
    query += `&cvs_download=true`
  }

  if (from) {
    query += `&fromDate=${from}`
  }

  if (to) {
    query += `&toDate=${to}`
  }

  return api.get("/v1/transfer-requests-fetch?" + query)
}

export const approveRejectRequestService = (id, status, comment) => {
  return api.put("/v1/requests", {
    id: id,
    status: status,
    comment: comment ?? null,
  })
}

export const approveRejectTransferFundsService = (id, status, comment) => {
  return api.post("/v1/admin-transfer-funds", {
    id: id,
    status: status,
    comment: comment ?? null,
  })
}

export const getAllUsersServices = (
  currentPage,
  type = "",
  limit = 5000,
  email,
  name,
  cvs_download = false,
  id = undefined,
  from = undefined,
  to = undefined
) => {
  let query = `page=${currentPage}&limit=${limit}`

  if (type) {
    query += `&type=${type}`
  }

  if (email) {
    query += `&email=${email}`
  }

  if (name) {
    query += `&name=${name}`
  }

  if (cvs_download) {
    query += `&cvs_download=true`
  }

  if (id) {
    query += `&user_id=${id}`
  }

  if (from) {
    query += `&fromDate=${from}`
  }

  if (to) {
    query += `&toDate=${to}`
  }

  return api.get(`/v1/users?${query}`)
}

export const getAllIBUsersServices = (
  currentPage,
  type = "",
  limit = 5000,
  email,
  name,
  cvs_download = false,
  id = undefined,
  from = undefined,
  to = undefined
) => {
  let query = `page=${currentPage}&limit=${limit}`

  if (type) {
    query += `&type=${type}`
  }

  if (email) {
    query += `&email=${email}`
  }

  if (name) {
    query += `&name=${name}`
  }

  if (cvs_download) {
    query += `&cvs_download=true`
  }

  if (id) {
    query += `&user_id=${id}`
  }

  if (from) {
    query += `&fromDate=${from}`
  }

  if (to) {
    query += `&toDate=${to}`
  }

  return api.get(`/v1/ib-users?${query}`)
}

export const getAllAccountsService = (
  currentPage,
  user_id = undefined,
  account_id = undefined,
  downloadFile = false,
  per_page = undefined,
  withoutLive = false,
  name = "",
  from = undefined,
  to = undefined
) => {
  let query = `page=${currentPage}&`

  if (user_id) {
    query += `&user_id=${user_id}`
  }

  // if (per_page) {
  //   query += `&limit=${1000}`
  // }

  if (account_id) {
    query += `&account_id=${account_id}`
  }

  if (downloadFile) {
    query += `&cvs_download=true`
  }

  if (withoutLive) {
    query += `&without_live=true`
  }

  if (name) {
    query += `&name=${name}`
  }

  if (from) {
    query += `&fromDate=${from}`
  }

  if (to) {
    query += `&toDate=${to}`
  }

  return api.get(`/v1/live-demo-accounts?${query}`)
}

export const getAllKYCsService = (
  currentPage,
  status = "ALL",
  user_id = undefined,
  downloadFile = false,
  name = undefined,
  from = undefined,
  to = undefined
) => {
  let query = `page=${currentPage}`

  if (user_id) {
    query += `&user_id=${user_id}`
  }

  if (name) {
    query += `&name=${name}`
  }

  if (status != "ALL") {
    query += `&status=${status}`
  }

  if (downloadFile) {
    query += `&cvs_download=true`
  }

  if (from) {
    query += `&fromDate=${from}`
  }

  if (to) {
    query += `&toDate=${to}`
  }

  return api.get(`/v1/admin/kyc?${query}`)
}

export const updateKYCStatusService = (id, status, comments) => {
  return api.put("/v1/admin/kyc", { id: id, status: status, comments })
}

export const getIBWithdrawalRequestsService = (
  type,
  currentPage,
  status,
  user_id,
  name
) => {
  let query = `page=${currentPage}`

  if (type) {
    query += `&type=${type}`
  }

  if (status) {
    query += `&status=${status}`
  }

  if (user_id) {
    query += `&user_id=${user_id}`
  }

  if (name) {
    query += `&name=${name}`
  }

  return api.get(`/v1/ib-wallet-requests-admin?${query}`)
}

export const updateIBWithdrawRequest = (id, status, comment) => {
  return api.put("/v1/ib-wallet-request", { id, status, comment })
}

export const getAllIBRequestsService = (
  currentPage,
  status = "ALL",
  user_id = undefined,
  downloadFile = false,
  name = undefined
) => {
  let query = `page=${currentPage}`

  if (user_id) {
    query += `&user_id=${user_id}`
  }

  if (name) {
    query += `&name=${name}`
  }

  if (status != "ALL") {
    query += `&status=${status}`
  }

  if (downloadFile) {
    query += `&cvs_download=true`
  }

  return api.get(`/v1/ib-request?${query}`)
}

export const getAllIBPlans = (currentPage = 1, limit = 1000) => {
  let query = `page=${currentPage}&limit=${limit}`

  return api.get(`/v1/ib-master-plan?${query}`)
}

export const makeUserIbService = (id, plan_id = 0) => {
  let data = {
    id: id,
  }

  if (plan_id) {
    data["master_account_id"] = plan_id
  }

  return api.put("/v1/request-ib", data)
}

export const changeParentService = (id, parent_id) => {
  return api.post("/v1/change-parent", { id, parent_id })
}

export const getAllIbPlanService = (currentPage = 1, limit = 1000) => {
  let query = `page=${currentPage}&limit=${limit}`

  return api.get(`/v1/ib-master-plan?${query}`)
}

export const createIBPlanService = (name) => {
  return api.post("/v1/ib-master-plan", { plan_name: name })
}

export const createIBPlanLevelsService = (plan_id, _levels) => {
  return api.post("/v1/add-level-commission", {
    account_master_id: plan_id,
    level_commission: _levels,
  })
}

export const updateIBPlanService = (_id, _name) => {
  return api.put("/v1/ib-master-plan", { id: _id, plan_name: _name })
}

export const updateIBPlanLevelService = (_level, _commission, _masterId) => {
  return api.put(`/v1/update-master-level`, {
    level: _level,
    commission: _commission,
    account_master_id: _masterId,
  })
}

export const deleteIBPlanLevelService = (_id) => {
  return api.delete(`/v1/delete-master-level?id=${_id}`)
}

export const fetchUserActivePlanService = (_userId) => {
  return api.get(`/v1/fetch-user-master-account?user_id=${_userId}`)
}

export const changeUserActivePlanService = (_user_id, _planId) => {
  return api.post("/v1/add-update-master-account", {
    user_id: _user_id,
    account_master_id: _planId,
  })
}

export const addNewUser = (data) => {
  return api.post("/v1/add-new-user", { ...data })
}

export const adminCreateTradingAccountService = (
  user_id,
  id,
  group,
  type = 0
) => {
  return api.post("/v1/new-account-create", {
    user_id: user_id,
    id: id,
    group: group,
    type: type,
  })
}

export const adminCreateDepositWithdrawalRequest = (data) => {
  return api.post("/v1/new-deposit-withdrawal-request", { ...data })
}

export const adminTransferCreditService = (data) => {
  return api.post("/v1/admin-credit-in-out", { ...data })
}

export const adminGetAllAccounts = () => {
  return api.get("/v1/all-accounts?limit=10000")
}

//
export const adminCreateInternalTransferService = (data) => {
  return api.post("/v1/internal-transfer-request", { ...data })
}

export const adminGetLiveAccountBalanceByAccountIDAndUserId = (
  user_id,
  account_id
) => {
  return api.get(
    `/v1/live-demo-accounts?user_id=${user_id}&account=${account_id}`
  )
}

export const getSettingsValueService = () => {
  return api.get("/v1/settings")
}

export const updateSettingValueService = (data) => {
  return api.put("/v1/settings", data)
}

export const adminCreateIBCommissionRequest = (data) => {
  // 'user_id','amount', 'type', 'status'

  return api.post("/v1/admin-create-ib-request", data)
}

export const getAdminUsersAndPermissions = () => {
  return api.get("/v1/users-and-permission?type=ADMIN")
}

export const getAllPermissions = () => {
  return api.get("/v1/permission?limit=1000")
}

export const createAdminUserService = (data) => {
  return api.post("/v1/admin-user", data)
}
export const deleteAdminUserService = (id) => {
  return api.delete("/v1/admin-user?id=" + id, { id: id })
}

// 'permission_id','model_id','model_type'
export const addPermissionToUserService = (data) => {
  return api.post("/v1/assign-permission-user", data)
}

export const deletePermissionFromUserService = (data) => {
  return api.delete(
    "/v1/delete-role-permission?" + new URLSearchParams(data).toString(),
    data
  )
}

export const spoofUserService = (id) => {
  return api.get(`/v1/fake-auth?id=${id}`)
}

export const getAdminDashboardService = () => {
  return api.get(`/v1/admin-dashboard`)
}

export const changeMT5AccountPasswordService = (
  loginId,
  investor_password,
  master_password
) => {
  return api.put("/v1/update-password-trading-account", {
    loginID: loginId,
    investor_password: investor_password,
    master_password: master_password,
  })
}

export const changeUserActiveStatusService = (id, status) => {
  return api.put(`v1/change-user-status`, { id: id, status: status })
}

export const addAdminAccount = (data) => {
  return api.post(`v1/admin-add-account`, data)
}

export const updateAccountDetailsService = (data) => {
  return api.put(`v1/admin-update-account`, data)
}

export const getAllGroups = () => {
  return api.get(`v1/groupcollection?limit=10000`)
}

export const createGroup = (data) => {
  return api.post(`v1/groupcollection`, data)
}

export const deleteGroup = (data) => {
  return api.delete(`v1/groupcollection?id=${data?.id}`, data)
}

export const syncAllGroups = () => {
  return api.get(`v1/sync-groupcollection`)
}

export const updateGroup = (data) => {
  return api.post(`v1/groupcollection`, data)
}

export const getAllAccountTypesService = () => {
  return api.get(`v1/account-master?limit=10000`)
}

export const createAccountType = () => {}

export const updateAccountType = () => {}

export const deleteAccountType = () => {}

export const updateUserService = (data) => {
  return api.put("v1/update-user-profile", data)
}

export const getUserBalanceService = (user_id) => {
  return api.get(`v1/walletBalance?user_id=${user_id}`)
}
