import React, { useEffect, useRef, useState } from "react"
import PageLayout from "../../layouts/PageLayout"
import { Col, Row } from "react-bootstrap"
import { CardHeader, CardLayout } from "../../components/cards"
import { Anchor, Box, Item, Text } from "../../components/elements"
import { Flex } from "@chakra-ui/layout"
import { useParams } from "react-router-dom"
import { LabelField, LabelTextarea } from "../../components/fields"
import {
  createTicketComment,
  getSupportTicketDetails,
  uploadImageForSupportTicket,
} from "../../services/ib"
import { useTimeAgo, useTimeAgoGrouped } from "../../utils/useTimeAgo"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useShowToast } from "../../hooks"
import {
  Divider,
  Center,
  Spinner,
  Avatar,
  AvatarGroup,
  Image,
} from "@chakra-ui/react"
import useSession from "../../hooks/useSession"
import _ from "lodash"
import Zoom from "react-medium-image-zoom"
import "react-medium-image-zoom/dist/styles.css"

const SupportOneView = () => {
  const params = useParams()
  const [data, setData] = useState()
  const { duration, time } = useTimeAgo(data?.created_at)
  const dateFormatedData = useTimeAgoGrouped(data?.replies)
  const [isFetching, setIsFetching] = useState(false)

  useEffect(() => {
    if (params.id) fetchTicketDetails()
  }, [params.id])

  async function fetchTicketDetails() {
    setIsFetching(true)
    const result = await getSupportTicketDetails(params.id)

    if (result?.data?.success) {
      const data = result?.data?.data?.support
      setData(data)
    } else {
      console.log("failed to fetch!")
    }
    setIsFetching(false)
  }

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <CardLayout style={{ display: "flex" }}>
            <Item className="mc-breadcrumb-item">
              <Anchor className="mc-breadcrumb-link" href="/dashboard">
                Home
              </Anchor>
            </Item>
            <Item className="mc-breadcrumb-item">
              <Anchor className="mc-breadcrumb-link" href="/support">
                Support
              </Anchor>
            </Item>
            <Item className="mc-breadcrumb-item">
              <Text>{params.id}</Text>
            </Item>
          </CardLayout>
        </Col>
      </Row>

      <Row>
        <Col xl={12}>
          {isFetching && !data ? (
            <Center marginTop="20px">
              <Spinner size="xl" />
            </Center>
          ) : (
            <CardLayout>
              {data ? (
                <>
                  <Box style={{ marginBottom: "10px" }}>
                    <CardHeader
                      title={`#${params?.id} ${data?.title}`}
                      style={{ marginBottom: -5 }}
                    />
                    <Text as="small" style={{ color: "gray" }}>
                      created {duration}
                    </Text>
                  </Box>
                  <CommentCard data={data} time={time} />

                  {_.map(dateFormatedData, (data, i) => {
                    return (
                      <Box key={i}>
                        <Flex alignItems="center">
                          <Divider />
                          <Text
                            as="small"
                            bg="white"
                            style={{ margin: "-2px 10px 0 10px" }}
                          >
                            {data.date}
                          </Text>
                          <Divider />
                        </Flex>
                        {data.items?.map((item, index) => (
                          <CommentCard key={index} data={item} />
                        ))}
                      </Box>
                    )
                  })}

                  <Divider style={{ borderColor: "lightgray" }} />
                  <CreateComment
                    data={data}
                    setData={setData}
                    id={params.id}
                    refetch={fetchTicketDetails}
                  />
                </>
              ) : (
                <Text>
                  Unable to get details of ticket id <b>#{params.id}</b>
                </Text>
              )}
            </CardLayout>
          )}
        </Col>
      </Row>
    </PageLayout>
  )
}

const getURL = (name) => {
  //return `http://localhost/nord-fx/storage/support/${name}`
  return `https://api.strelasoft.com/democrm/server.php/storage/support/${name}`
}

const CommentCard = ({ data, time }) => {
  const [user] = useSession()
  const currentUser = data.user_id === user.id
  const firstComment = !data.parent_id
  const name = currentUser ? data.name : "Support"

  return (
    <Flex
      style={{
        flexDirection: currentUser ? "row-reverse" : "",
        marginBottom: "10px",
      }}
    >
      <Box
        style={{
          width: firstComment ? "100%" : "",
          minWidth: "20%",
          maxWidth: firstComment ? "auto" : "80%",
        }}
      >
        <Flex
          alignItems="flex-end"
          flexDirection={currentUser ? "row-reverse" : ""}
          gap={2}
        >
          <Avatar
            size="sm"
            name={name}
            color="black"
            bg="#f9f9fb"
            border="1px solid lightgray"
          />
          <Box style={{ width: "100%" }}>
            <Text as="small" style={{ fontWeight: "500" }}>
              {name}
            </Text>
            <Box
              style={{
                border: "1px solid lightgray",
                borderRadius: "5px",
                padding: "5px 10px",
                backgroundColor: "#f9f9fb",
              }}
            >
              <Text style={{ fontWeight: "500", lineHeight: 1.4 }}>
                {data.title}
              </Text>
              <Text style={{ fontSize: "14px" }}>{data.support_text}</Text>
              <Text
                style={{
                  fontSize: "10px",
                  textAlign: "right",
                  color: "gray",
                  lineHeight: "1.2",
                }}
              >
                {firstComment ? time : data.time}
              </Text>
            </Box>

            <Flex>
              <AvatarGroup gap={4} mt={4}>
                {_.map(data?.media, (m) => {
                  return (
                    <>
                      <Zoom>
                        <Image
                          boxSize="50px"
                          objectFit="cover"
                          src={getURL(m?.media_url)}
                          alt="Support"
                        />
                      </Zoom>
                    </>
                  )
                })}
              </AvatarGroup>
            </Flex>
          </Box>
        </Flex>
      </Box>
    </Flex>
  )
}

const commentSchema = Yup.object().shape({
  title: Yup.string().required("Title is required!"),
  description: Yup.string().required("Description is required!"),
})

const CreateComment = ({ data, setData, id, refetch }) => {
  const [user] = useSession()
  const showToast = useShowToast()
  const imageUploadRef = useRef()

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      priority: "",
      images: null,
    },
    enableReinitialize: true,
    validationSchema: commentSchema,
    onSubmit: (values, actions) => {
      handleComment(values, actions)
    },
  })

  const handleComment = async (values, actions) => {
    actions.setSubmitting(true)

    try {
      const parent_id = data.id
      const priority = data.priority

      const result = await createTicketComment(
        values?.title,
        values?.description,
        priority,
        parent_id
      )

      if (result?.data?.success) {
        const reply = result?.data?.data?.support

        if (values?.images) {
          try {
            const uploadFileResult = await uploadImageForSupportTicket(
              result?.data?.data?.support?.id,
              values?.images
            )
          } catch (error) {}
        }

        console.log()
        await refetch()

        // setData({
        //   ...data,
        //   replies: [...data.replies, { ...reply, name: user.name }],
        // })
        showToast("Success", "Ticket comment created successfully")
        actions?.resetForm()
        actions.setSubmitting(false)

        if (imageUploadRef?.current?.value) {
          imageUploadRef.current.value = ""
        }

        return
      }
    } catch (error) {}

    showToast("Error", "Something went wrong", "error")
    actions.setSubmitting(false)
  }

  return (
    <Box style={{ marginTop: "20px" }}>
      <CardHeader title="Add a comment!" />

      <Flex flexDirection="column" gap={2}>
        <LabelField
          label="Title"
          type="text"
          fieldSize="w-100 h-md"
          name={"title"}
          value={formik?.values.title}
          onChange={formik?.handleChange}
          errorMessage={
            formik?.errors?.title && formik?.touched?.title
              ? formik?.errors?.title
              : false
          }
        />
        <LabelTextarea
          fieldSize="w-100"
          label="Description"
          name={"description"}
          value={formik?.values.description}
          onChange={formik?.handleChange}
          errorMessage={
            formik?.errors?.description && formik?.touched?.description
              ? formik?.errors?.description
              : false
          }
        />

        <LabelField
          ref={imageUploadRef}
          label="Attach a file"
          type="file"
          fieldSize="w-100 h-md"
          accept=".jpg, .jpeg, .png"
          name="images"
          onBlur={formik.handleBlur}
          multiple="multiple"
          onChange={(e) => {
            formik.setFieldTouched("images", true)

            if (e?.currentTarget?.files > 5) {
              formik?.setFieldError(
                "images",
                "Maximum of only 5 files are allowed"
              )
            }

            formik.setFieldValue("images", e?.currentTarget?.files ?? null)
          }}
          errorMessage={
            formik?.errors?.images && formik?.touched?.images
              ? formik?.errors?.images
              : false
          }
        />
      </Flex>

      <Box style={{ marginTop: "20px" }}>
        <button
          type="button"
          className={"mc-btn primary h-sm"}
          disabled={formik?.isSubmitting}
          onClick={formik.handleSubmit}
          style={{ display: "flex", alignItems: "center" }}
        >
          {formik?.isSubmitting ? <Spinner size="xs" /> : "Submit"}
        </button>
      </Box>
    </Box>
  )
}

export default SupportOneView
